<template>
  <div class="page-name page-content">
    <KlubbaRichText class="texts">
      <p><b>{{ progress.title }}</b></p>
      <p>{{ progress.description }}</p>
    </KlubbaRichText>

    <div class="container">
      <div class="card-wrap" :class="{ 'is-loading': loading }">
        <!-- Name -->
        <div class="page-other-section">
          <div class="page-other-label required">First Name</div>
          <klubba-input
            v-model="v$.newUserInfo.first_name.$model"
            :customClass="customClass"
            :class="{ 'error': v$.newUserInfo.first_name.$error }"
          />
        </div>

        <!-- Last name -->
        <div class="page-other-section">
          <div class="page-other-label required">Last Name</div>
          <klubba-input
            v-model="v$.newUserInfo.last_name.$model"
            :customClass="customClass"
            :class="{ 'error': v$.newUserInfo.last_name.$error }"
          />
        </div>

        <!-- Date of birth -->
        <div class="page-other-section">
          <div class="page-other-label required">Date of birth</div>
          <klubba-date-picker
            v-model="newUserInfo.date_of_birth"
            birthday
            :class="{ 'error': v$.newUserInfo.date_of_birth.$error }"
          />
        </div>

        <KlubbaLoaderIcon :active="loading" />
      </div>
    </div>

    <div class="button-wrap">
      <klubba-footer
        backButton="Back"
        @goPrevPage="prevPage"
        @goNextPage="nextPage"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { useVuelidate } from '@vuelidate/core';

import KlubbaFooter from '@/components/layout/KlubbaFooter.vue';
import KlubbaInput from '@/components/default/KlubbaInput.vue';
import KlubbaDatePicker from '@/components/default/KlubbaDatePicker.vue';
import KlubbaRichText from '../../components/default/KlubbaRichText.vue';
import firebaseApp from '../../config/firebase';
import KlubbaLoaderIcon from '../../components/default/KlubbaLoaderIcon.vue';

export default {
  components: {
    KlubbaLoaderIcon,
    KlubbaRichText,
    KlubbaFooter,
    KlubbaInput,
    KlubbaDatePicker,
  },
  data() {
    return {
      progress: {
        title: 'Tell us a little about yourself',
        description: 'This name must match your ID if requested for proof of ID.',
      },
      newUserInfo: {
        first_name: '',
        last_name: '',
        date_of_birth: '',
      },
      customClass: 'border-b border-black border-opacity-10 h-8 pb-3',
      loading: false,
    };
  },
  setup: () => ({
    v$: useVuelidate(),
  }),
  mounted() {
    this.newUserInfo.first_name = this.$store.getters.getUserKey('first_name') ?? '';
    this.newUserInfo.last_name = this.$store.getters.getUserKey('last_name') ?? '';
    this.newUserInfo.date_of_birth = this.$store.getters.getUserKey('date_of_birth') ?? '';
  },
  validations() {
    return {
      newUserInfo: {
        first_name: { required },
        last_name: { required },
        date_of_birth: { required },
      },
    };
  },
  computed: {
    club() {
      return this.$store.getters.club;
    },
    firebaseUser() {
      return this.$store.getters.firebaseUser;
    },
  },
  methods: {
    prevPage() {
      this.$router.back();
    },
    async nextPage() {
      if (this.v$.$invalid) {
        console.log('Please check invalid fields');
        return;
      }

      // Save info
      this.loading = true;
      const db = firebaseApp.firestore();
      await this.$store.commit('setNewUser', this.newUserInfo);

      const userQuery = await db.collection('users').doc(this.firebaseUser.uid).get();
      if (userQuery.exists) {
        // Update firebase
        await db.collection('users').doc(this.firebaseUser.uid).update(this.$store.getters.newUser)
          .then(() => {
            console.log('Firebase doc in users collection updated');
            this.$router.push(`/${this.club.id}/other`);
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      } else {
        this.loading = false;
        this.$router.push(`/${this.club.id}/other`);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.texts
  @apply p-8

.container
  @apply px-8

.card-wrap
  @apply bg-white rounded-radius-10 p-5 relative
  &.is-loading .page-other-section
    opacity: .3

.page-other-section
  @apply block
  transition: opacity var(--transition)

  &:not(:first-child)
    @apply mt-8

.page-other-label
  @apply text-sm text-black-light leading-4 tracking-spacing-7 mb-3
  &.required
    &::after
      content: ' *'
      @apply text-danger

.button-wrap
  @apply p-8 mt-auto
</style>
